import React, { useCallback, useLayoutEffect, useState } from "react";
import {
  Badge,
  Card,
  Col,
  Dropdown,
  Form,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { HexAlphaColorPicker } from "react-colorful";
import { useForm, useWatch } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
import Select from "../../../components/UI/Select";
import Textarea from "../../../components/UI/Textarea";
import {
  createTerminal,
  editTerminal,
  getTerminals,
} from "../../../services/terminal";
import CustomModal from "../../../components/utils/CustomModal";
import { IoEllipsisVertical } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const syncAuthData = [
  {
    title: "По почте",
    value: "email",
  },
  {
    title: "По номеру телефона",
    value: "phone",
  },
];

const syncThemeTypeData = [
  {
    title: "Системная (По умолчанию)",
    value: false,
  },
  {
    title: "Темная",
    value: "dark",
  },
  {
    title: "Светлая",
    value: "light",
  },
];

const syncPointData = [
  {
    title: "Номер телефона",
    value: "phone",
  },
  {
    title: "Email",
    value: "email",
  },
  {
    title: "Идентификатор из интеграции",
    value: "apiId",
  },
];

const syncThemeProduct = [
  {
    title: "Средний размер изображения",
    value: 0,
  },
  {
    title: "Большой размер изображения",
    value: 1,
  },
];

const syncThemeProductImage = [
  {
    title: "Квадрат",
    value: 0,
  },
  {
    title: "Прямоугольник",
    value: 1,
  },
];

const syncProductColumn = [
  {
    title: "Один в ряд",
    value: 0,
  },
  {
    title: "Два в ряд",
    value: 1,
  },
];

const syncThemeAddition = [
  {
    title: "Список по вертикали",
    value: 0,
  },
  {
    title: "Список по горизонтали",
    value: 1,
  },
];

const KioskForm = ({ data, onSubmit }) => {
  const { t } = useTranslation();
  const brand = useSelector((state) => state?.brand?.active);
  const affiliates = useSelector((state) => state.affiliate.data);

  const [terminals, setTerminals] = useState({
    items: [],
    pagination: false,
  });
  const [terminal, setTerminal] = useState({
    show: false,
    data: {
      title: null,
      status: true,
    },
  });
  const [editTerminalData, setEditTerminal] = useState({
    show: false,
    data: {
      title: null,
      status: true,
    },
  });

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues:
      data && !Array.isArray(data)
        ? data
        : {
            cart: true,
            logo: "",
            menu: [],
            info: {
              banner: null,
              vk: {
                value: null,
                status: false,
              },
              phone: {
                value: null,
                status: false,
              },
              telegram: {
                value: null,
                status: false,
              },
              whatsapp: {
                value: null,
                status: false,
              },
              instagram: {
                value: null,
                status: false,
              },
            },
            name: "",
            title: "",
            pickup: {
              card: true,
              cash: true,
              online: false,
            },
            qrType: "email",
            widget: [],
            domains: [],
            favicon: "",
            version: "0.0.1",
            authType: "email",
            colorBtn: "#ff5252",
            delivery: {
              card: true,
              cash: true,
              online: false,
            },
            colorMain: "#ff5252",
            metrikaId: "",
            themeType: "",
            multiBrand: false,
            giftVisible: false,
            promoVisible: false,
            supportVisible: true,
            themeProductColumn: 1,
            checkoutSuccessText: "",
            profilePointVisible: true,
            productEnergyVisible: false,
          },
  });

  const form = useWatch({ control });

  // const onSubmit = useCallback((data) => {
  //   var formData = new FormData();
  //   formData.append("dataParse", JSON.stringify(data));
  //   if (data?.feedback) {
  //     for (let file of data.feedback) {
  //       formData.append("feedback", file);
  //     }
  //   }
  //   if (data?.auth) {
  //     for (let file of data.auth) {
  //       formData.append("auth", file);
  //     }
  //   }
  //   editModuleWeb(formData)
  //     .then(() => {
  //       NotificationManager.success("Терминал успешно обновлен");
  //     })
  //     .catch(
  //       (err) => err && NotificationManager.error("Ошибка при сохранении")
  //     );
  // }, []);

  const onCreateTerminal = useCallback(() => {
    createTerminal(terminal.data)
      .then(() => {
        NotificationManager.success("Терминал успешно добавлена");
        getTerminals().then((res) => {
          setTerminals(res);
        });
        setTerminal({ show: false, data: { status: true } });
      })
      .catch((error) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      );
  }, [terminal]);

  const onEditTerminal = useCallback(() => {
    editTerminal(editTerminalData.data)
      .then(() => {
        NotificationManager.success("Терминал успешно обновлен");
        getTerminals().then((res) => {
          setTerminals(res);
        });
        setEditTerminal({ show: false, data: { status: true } });
      })
      .catch((error) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      );
  }, [editTerminalData]);

  useLayoutEffect(() => {
    getTerminals().then((res) => {
      setTerminals(res);
    });
  }, []);

  return (
    <Card body className="mb-4">
      <h3 className="mb-4 fw-7">Настройки</h3>
      <Tabs defaultActiveKey={0} className="mb-3" fill>
        <Tab eventKey={0} title="Основное">
          <Row>
            <Col md={6}>
              <Form.Check className="mb-4 d-inline-block me-3">
                <Form.Check.Input
                  type="checkbox"
                  name="relationModule.options.multiBrand"
                  id="multiBrand"
                  defaultChecked={form?.relationModule?.options?.multiBrand}
                  {...register("relationModule.options.multiBrand")}
                />
                <Form.Check.Label htmlFor="multiBrand" className="ms-2">
                  Мультибренд
                </Form.Check.Label>
                <small className="ms-2 text-muted fs-07">
                  Вкл\Откл выбор разных заведений
                </small>
              </Form.Check>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Тип аутентификации"
                  onClick={(e) =>
                    setValue("relationModule.options.authType", e.value)
                  }
                  value={form?.relationModule?.options?.authType ?? "phone"}
                  data={syncAuthData}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="border rounded-3 mb-3 p-3 bg-light">
                <h5 className="mb-3 fw-7">Профиль</h5>
                <div className="mb-3">
                  <Select
                    onClick={(e) =>
                      setValue("relationModule.options.qrType", e.value)
                    }
                    value={form?.relationModule?.options?.qrType ?? "phone"}
                    data={syncPointData}
                  />
                  <small className="fs-08 text-muted">
                    Значение при сканировании QR
                  </small>
                </div>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    name="relationModule.options.profilePointVisible"
                    id="profilePointVisible"
                    defaultChecked={
                      form?.relationModule?.options?.profilePointVisible
                    }
                    {...register("relationModule.options.profilePointVisible")}
                  />
                  <Form.Check.Label
                    htmlFor="profilePointVisible"
                    className="ms-2"
                  >
                    Включить бонусы в профиле
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    name="relationModule.options.supportVisible"
                    id="supportVisible"
                    defaultChecked={
                      form?.relationModule?.options?.supportVisible
                    }
                    {...register("relationModule.options.supportVisible")}
                  />
                  <Form.Check.Label htmlFor="supportVisible" className="ms-2">
                    Включить тех поддержку
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="border rounded-3 mb-3 p-3 bg-light">
                <h5 className="mb-3 fw-7">Оформление заказа</h5>

                <Textarea
                  placeholder='Статус заказа вы можете узнать в личном профиле, в разделе "Заказы".'
                  name="relationModule.options.checkoutSuccessText"
                  errors={errors}
                  register={register}
                  rows={6}
                  validation={{
                    maxLength: {
                      value: 1500,
                      message: "Максимально 500 символов",
                    },
                  }}
                  defaultValue={
                    form?.relationModule?.options?.checkoutSuccessText
                  }
                />
                <small className="fs-08 text-muted">
                  Текст успешной отправки заказа
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="border rounded-3 mb-3 p-3 bg-light">
                <h5 className="mb-3 fw-7">Товар</h5>
                <Form.Check className="d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    name="relationModule.options.productEnergyVisible"
                    id="productEnergyVisible"
                    defaultChecked={
                      form?.relationModule?.options?.productEnergyVisible
                    }
                    {...register("relationModule.options.productEnergyVisible")}
                  />
                  <Form.Check.Label
                    htmlFor="productEnergyVisible"
                    className="ms-2"
                  >
                    Включить пищевую ценность
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="border rounded-3 mb-3 p-3 bg-light">
                <h5 className="mb-3 fw-7">Корзина</h5>
                <Form.Check className="mb-4 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    name="relationModule.options.cart"
                    id="cart"
                    defaultChecked={form?.relationModule?.options?.cart}
                    {...register("relationModule.options.cart")}
                  />
                  <Form.Check.Label htmlFor="cart" className="ms-2">
                    Включить корзины
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-4 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    name="relationModule.options.promoVisible"
                    id="promoVisible"
                    defaultChecked={form?.relationModule?.options?.promoVisible}
                    {...register("relationModule.options.promoVisible")}
                  />
                  <Form.Check.Label htmlFor="promoVisible" className="ms-2">
                    Включить промокоды
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    name="relationModule.options.giftVisible"
                    id="giftVisible"
                    defaultChecked={form?.relationModule?.options?.giftVisible}
                    {...register("relationModule.options.giftVisible")}
                  />
                  <Form.Check.Label htmlFor="giftVisible" className="ms-2">
                    Включить выбор подарков
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey={3} title="Оплата и заявки">
          <Row>
            <Col md={4}>
              <div className="border rounded-3 mb-3 p-3 bg-light">
                <p className="mb-3 fw-6 border-bottom pb-3 d-flex align-items-center justify-content-between">
                  Доставка
                  <Form.Check
                    id="status-delivery"
                    type="switch"
                    className="switch"
                    defaultChecked={
                      form?.relationModule?.options?.dellivery?.status
                    }
                    {...register("relationModule.options.delivery.status")}
                  />
                </p>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="online-delivery"
                    defaultChecked={
                      form?.relationModule?.options?.delivery?.online
                    }
                    {...register("relationModule.options.delivery.online")}
                  />
                  <Form.Check.Label htmlFor="online-delivery" className="ms-2">
                    Онлайн оплата
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="card-delivery"
                    defaultChecked={
                      form?.relationModule?.options?.delivery?.card
                    }
                    {...register("relationModule.options.delivery.card")}
                  />
                  <Form.Check.Label htmlFor="card-delivery" className="ms-2">
                    Банковской картой
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="cash-delivery"
                    defaultChecked={
                      form?.relationModule?.options?.delivery?.cash
                    }
                    {...register("relationModule.options.delivery.cash")}
                  />
                  <Form.Check.Label htmlFor="cash-delivery" className="ms-2">
                    Наличными
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Col>
            <Col md={4}>
              <div className="border rounded-3 mb-3 p-3 bg-light">
                <p className="mb-3 border-bottom pb-3 fw-6 d-flex align-items-center justify-content-between">
                  Самовывоз
                  <Form.Check
                    id="status-pickup"
                    type="switch"
                    className="switch"
                    defaultChecked={
                      form?.relationModule?.options?.pickup?.status
                    }
                    {...register("relationModule.options.pickup.status")}
                  />
                </p>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="online-pickup"
                    defaultChecked={
                      form?.relationModule?.options?.pickup?.online
                    }
                    {...register("relationModule.options.pickup.online")}
                  />
                  <Form.Check.Label htmlFor="online-pickup" className="ms-2">
                    Онлайн оплата
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="card-pickup"
                    defaultChecked={form?.relationModule?.options?.pickup?.card}
                    {...register("relationModule.options.pickup.card")}
                  />
                  <Form.Check.Label htmlFor="card-pickup" className="ms-2">
                    Банковской картой
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="cash-pickup"
                    defaultChecked={form?.relationModule?.options?.pickup?.cash}
                    {...register("relationModule.options.pickup.cash")}
                  />
                  <Form.Check.Label htmlFor="cash-pickup" className="ms-2">
                    Наличными
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Col>
            <Col md={4}>
              <div className="border rounded-3 mb-3 p-3 bg-light">
                <p className="mb-3 fw-6 border-bottom pb-3 d-flex align-items-center justify-content-between">
                  В зале
                  <Form.Check
                    id="status-hall"
                    type="switch"
                    className="switch"
                    defaultChecked={form?.relationModule?.options?.hall?.status}
                    {...register("relationModule.options.hall.status")}
                  />
                </p>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="online-hall"
                    defaultChecked={form?.relationModule?.options?.hall?.online}
                    {...register("relationModule.options.hall.online")}
                  />
                  <Form.Check.Label htmlFor="online-hall" className="ms-2">
                    Онлайн оплата
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="card-hall"
                    defaultChecked={form?.relationModule?.options?.hall?.card}
                    {...register("relationModule.options.hall.card")}
                  />
                  <Form.Check.Label htmlFor="card-hall" className="ms-2">
                    Банковской картой
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="cash-hall"
                    defaultChecked={form?.relationModule?.options?.hall?.cash}
                    {...register("relationModule.options.hall.cash")}
                  />
                  <Form.Check.Label htmlFor="cash-hall" className="ms-2">
                    Наличными
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Col>
            {brand?.type === "service" && (
              <Col md={4}>
                <div className="border rounded-3 mb-3 p-3 bg-light">
                  <p className="mb-3 fw-6 border-bottom pb-3 d-flex align-items-center justify-content-between">
                    Обратная связь
                    <Form.Check
                      id="status-feedback"
                      type="switch"
                      className="switch"
                      defaultChecked={
                        form?.relationModule?.options?.feedback?.status
                      }
                      {...register("relationModule.options.feedback.status")}
                    />
                  </p>
                  <span className="text-muted fs-09">Настроек пока нет</span>
                </div>
              </Col>
            )}
          </Row>
        </Tab>
        <Tab eventKey={4} title="Тема">
          <Row>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Тема"
                  onClick={(e) =>
                    setValue("relationModule.options.themeType", e.value)
                  }
                  value={form?.relationModule?.options?.themeType ?? false}
                  data={syncThemeTypeData}
                />
              </div>
            </Col>
          </Row>
          <h5 className="mb-4">Цвета</h5>
          <Row>
            <Col md={12} className="d-flex flex-wrap">
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Основное</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorMain}
                  onChange={(e) =>
                    setValue("relationModule.options.colorMain", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorMain", e)
                  }
                  value={form?.relationModule?.options?.colorMain}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Кнопка</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorBtn}
                  onChange={(e) =>
                    setValue("relationModule.options.colorBtn", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorBtn", e)
                  }
                  value={form?.relationModule?.options?.colorBtn}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Текст</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorText}
                  onChange={(e) =>
                    setValue("relationModule.options.colorText", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorText", e)
                  }
                  value={form?.relationModule?.options?.colorText}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Заголовок</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorTextTitle}
                  onChange={(e) =>
                    setValue("relationModule.options.colorTextTitle", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorTextTitle", e)
                  }
                  value={form?.relationModule?.options?.colorTextTitle}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Подзаголовок</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorTextSubtitle}
                  onChange={(e) =>
                    setValue("relationModule.options.colorTextSubtitle", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorTextSubtitle", e)
                  }
                  value={form?.relationModule?.options?.colorTextSubtitle}
                />
              </div>
            </Col>
          </Row>
          <h5 className="mb-4">Товары</h5>
          <Row>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Вид товара"
                  onClick={(e) =>
                    setValue("relationModule.options.themeProduct", e.value)
                  }
                  value={form?.relationModule?.options?.themeProduct ?? 0}
                  data={syncThemeProduct}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Вид изображения товара"
                  onClick={(e) =>
                    setValue(
                      "relationModule.options.themeProductImage",
                      e.value
                    )
                  }
                  value={form?.relationModule?.options?.themeProductImage ?? 0}
                  data={syncThemeProductImage}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Вид ряда"
                  onClick={(e) =>
                    setValue(
                      "relationModule.options.themeProductColumn",
                      e.value
                    )
                  }
                  value={form?.relationModule?.options?.themeProductColumn ?? 0}
                  data={syncProductColumn}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Вид допов"
                  onClick={(e) =>
                    setValue("relationModule.options.themeAddition", e.value)
                  }
                  value={form?.relationModule?.options?.themeAddition ?? 0}
                  data={syncThemeAddition}
                />
              </div>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey={5} title="Терминалы">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h5>Мои терминалы</h5>
            <Button
              onClick={() => setTerminal((prev) => ({ ...prev, show: true }))}
            >
              Добавить терминал
            </Button>
          </div>
          {terminals?.items?.length > 0 ? (
            terminals.items.map((e) => (
              <div className="mb-3 bg-light rounded p-3 py-2 d-flex flex-row justify-content-between align-items-center">
                <div className="me-3 fs-09">
                  <p className="fw-6">{e.title ?? "Терминал #" + e.id}</p>
                  <p className="fs-08 text-muted">
                    {e.apiId ?? e.code ?? e.desc}
                  </p>
                </div>
                <div className="me-3 fs-09">
                  {e.brand} {e.osName} {e.osVersion}
                  <p className="fs-08 text-muted">
                    {e.ip}
                    {e.version ? ` (${e.version})` : ""}
                  </p>
                </div>
                <a className="me-3">
                  <p className="fs-08 text-muted mb-1">Филиал</p>
                  {e.affiliateId ? (
                    <Badge className="badge-sm" bg="success">
                      Подключен
                    </Badge>
                  ) : (
                    <Badge className="badge-sm" bg="secondary">
                      Нет
                    </Badge>
                  )}
                </a>
                <a className="me-3">
                  <p className="fs-08 text-muted mb-1">Статус</p>
                  {e.status ? (
                    <Badge bg="success" className="badge-sm">
                      Активно
                    </Badge>
                  ) : (
                    <Badge bg="danger" className="badge-sm">
                      В ожидании
                    </Badge>
                  )}
                </a>
                <div>
                  <Dropdown>
                    <Dropdown.Toggle
                      as={React.forwardRef(({ children, onClick }, ref) => (
                        <a
                          ref={ref}
                          className="py-0"
                          onClick={(e) => {
                            e.preventDefault();
                            onClick(e);
                          }}
                        >
                          <IoEllipsisVertical size={20} color="#999" />
                        </a>
                      ))}
                    />
                    <Dropdown.Menu align="end">
                      <Dropdown.Item
                        onClick={() =>
                          setEditTerminal((prev) => ({
                            ...prev,
                            show: true,
                            data: e,
                          }))
                        }
                      >
                        {t("Изменить")}
                      </Dropdown.Item>
                      <Dropdown.Item className="text-danger">
                        {t("Удалить")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center fs-09 text-muted py-4">
              У вас нет терминалов
            </p>
          )}
        </Tab>
      </Tabs>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <span className="text-muted fs-08 me-3">
          Данные применяться в течении 1 мин
        </span>
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
      <CustomModal
        title="Редактировать терминал"
        show={editTerminalData.show}
        setShow={(e) => setEditTerminal((prev) => ({ ...prev, show: e }))}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setEditTerminal((prev) => ({ ...prev, show: false }))
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => {
                onEditTerminal();
              }}
            >
              Сохранить терминал
            </Button>
          </>
        }
      >
        <div className="mb-3">
          <Input
            defaultValue={editTerminalData.data?.title ?? ""}
            label="Название"
            placeholder="Введите название (необязательно)"
            onChange={(e) =>
              setEditTerminal((prev) => ({
                ...prev,
                data: { ...prev.data, title: e },
              }))
            }
          />
        </div>
        <div className="mb-3">
          <Input
            defaultValue={editTerminalData.data?.key ?? ""}
            label="Код"
            placeholder="Введите код (необязательно)"
            onChange={(e) =>
              setEditTerminal((prev) => ({
                ...prev,
                data: { ...prev.data, key: e },
              }))
            }
          />
        </div>
        <div className="mb-3">
          <Select
            label="Филиал"
            onClick={(e) =>
              setEditTerminal((prev) => ({
                ...prev,
                data: { ...prev.data, affiliateId: e.value },
              }))
            }
            value={editTerminalData?.data?.affiliateId}
            data={
              affiliates?.length > 0
                ? affiliates.map((e) => ({ title: e.full, value: e.id }))
                : []
            }
          />
        </div>
        <Form.Check className="d-inline-block me-3">
          <Form.Check.Input
            type="checkbox"
            id="edit-status-terminal"
            defaultChecked={editTerminalData.data?.status}
            onChange={(e) =>
              setEditTerminal((prev) => ({
                ...prev,
                data: { ...prev.data, status: e.target.checked },
              }))
            }
          />
          <Form.Check.Label htmlFor="edit-status-terminal" className="ms-2">
            Вкл/Выкл терминал
          </Form.Check.Label>
        </Form.Check>
      </CustomModal>
      <CustomModal
        title="Новый терминал"
        show={terminal.show}
        setShow={(e) => setTerminal((prev) => ({ ...prev, show: e }))}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setTerminal((prev) => ({ ...prev, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => {
                onCreateTerminal();
              }}
            >
              Сохранить терминал
            </Button>
          </>
        }
      >
        <div className="mb-3">
          <Input
            defaultValue={terminal.data?.title ?? ""}
            label="Название"
            placeholder="Введите название (необязательно)"
            onChange={(e) =>
              setTerminal((prev) => ({
                ...prev,
                data: { ...prev.data, title: e },
              }))
            }
          />
        </div>
        <div className="mb-3">
          <Input
            defaultValue={terminal.data?.key ?? ""}
            label="Код"
            placeholder="Введите код (необязательно)"
            onChange={(e) =>
              setTerminal((prev) => ({
                ...prev,
                data: { ...prev.data, key: e },
              }))
            }
          />
        </div>
        <div className="mb-3">
          <Select
            label="Филиал"
            onClick={(e) =>
              setTerminal((prev) => ({
                ...prev,
                data: { ...prev.data, affiliateId: e.value },
              }))
            }
            value={terminal?.data?.affiliateId}
            data={
              affiliates?.length > 0
                ? affiliates.map((e) => ({ title: e.full, value: e.id }))
                : []
            }
          />
        </div>
        <Form.Check className="d-inline-block me-3">
          <Form.Check.Input
            type="checkbox"
            id="status-terminal"
            defaultChecked={terminal.data?.status}
            onChange={(e) =>
              setTerminal((prev) => ({
                ...prev,
                data: { ...prev.data, status: e.target.checked },
              }))
            }
          />
          <Form.Check.Label htmlFor="status-terminal" className="ms-2">
            Вкл/Выкл терминал
          </Form.Check.Label>
        </Form.Check>
      </CustomModal>
    </Card>
  );
};
export default KioskForm;
