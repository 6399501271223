import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { IoAdd, IoCreateOutline, IoTrashOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { deleteBlog, getBlogs } from "../../services/blog";

const Blogs = () => {
  const brand = useSelector((state) => state.brand.active);
  const affiliate = useSelector((state) => state.affiliate.active);
  const [searchParams, setSearchParams] = useSearchParams();
  const [blogs, setBlogs] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const blogColumns = [
    {
      name: "Фото",
      width: "60px",
      selector: "media",
      cell: (row) => (
        <Link to={"/content/blog/" + row.id}>
          <img
            src={getImageURL({ path: row.media, type: "blog" })}
            width={30}
            height={30}
            className="mini-img"
          />
        </Link>
      ),
    },
    {
      name: "Название",
      selector: "title",
      cell: (row) =>
        row?.id && row?.title ? (
          <Link to={`/content/blog/${row.id}`}>{row.title}</Link>
        ) : (
          "-"
        ),
    },
    {
      name: "Просмотров",
      selector: "view",
      width: "120px",
    },
    {
      width: "100px",
      name: "Филиал",
      align: "center",
      selector: "relationBlogs",
      cell: (row) =>
        row?.relationBlogs?.length === 0 ? (
          <Badge bg="secondary">Нет</Badge>
        ) : (
          <Badge bg="success">{row?.relationBlogs?.length}</Badge>
        ),
    },
    {
      width: "100px",
      name: "Статус",
      align: "center",
      selector: "status",
      cell: (row) =>
        row.status === 0 ? (
          <Badge bg="secondary">Архив</Badge>
        ) : (
          <Badge bg="success">Активно</Badge>
        ),
    },
    {
      width: "100px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Link to={"/content/blog/" + row.id} className="me-3">
            <IoCreateOutline size={22} />
          </Link>
          <a
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
            className="me-2"
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];
  const header = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h5 className="fw-7">
            {selected.length > 0 ? `Выбрано ${selected.length}` : "Новости"}
          </h5>
        </div>
        <div className="d-flex align-items-center">
          <Link to="create" className="btn-primary-outline me-3">
            <IoAdd size={18} />
          </Link>
          <Button
            disabled={selected.length === 0}
            className="btn-light"
            onClick={() => setModalDelete({ show: true, id: false })}
          >
            <IoTrashOutline size={18} />
          </Button>
        </div>
      </div>
    );
  }, [selected, searchParams, modalDelete]);

  const getData = useCallback(async () => {
    const paramsObject = {};
    searchParams.forEach((value, key) => {
      paramsObject[key] = value;
    });

    if (affiliate && affiliate.id) {
      paramsObject.affiliateId = affiliate.id;
    }
    getBlogs(paramsObject)
      .then(
        (res) =>
          res &&
          setBlogs((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setBlogs((prev) => ({ ...prev, loading: false })));
  }, [searchParams, brand, affiliate]);

  const onDelete = useCallback((id) => {
    deleteBlog(id)
      .then(() => {
        getData();
        NotificationManager.success("Документ успешно удален");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteBlog(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные документы успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page"), brand, affiliate]);

  if (blogs.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Новости" />
      <DataTable
        columns={blogColumns}
        onChange={(items) => setSelected(items)}
        data={blogs.items}
        header={header}
        selectable
        linkPagination="/options/blogs/"
        statusBackground="blog"
        pagination={blogs.pagination}
      />
      <CustomModal
        title={
          selected.length > 0
            ? `Удаление ${selected.length} элементов`
            : "Удаление элемента"
        }
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className="me-3"
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить документ(-ы)?
      </CustomModal>
    </>
  );
};

export default Blogs;
